export function menu() {
    const burger = document.querySelectorAll('.burger-script');
    const menu = document.querySelector('.nav');
    burger.forEach(item => {
        item.addEventListener('click', function(){
            $(burger).toggleClass('active')
            $(menu).toggleClass('active')
            $('body').toggleClass('overflow-hidden')
        })
    })
}

import $ from "jquery";


// внутренние модули

// пример подуючения:
import {
     mainSlider,
     sldierGallery,
     sliderMaterial,
     otherSlider,
     careersSlider,
} from "./modules/sliders";

import {
    mapPosition
} from "./modules/map";


// Внешние модули

import {
    menu,
} from "/src/modules/nav/nav.js";


$(function () {
    //Вывод всех функций всех модулей
    mainSlider();
    sldierGallery();
    menu();
    mapPosition();
    sliderMaterial();
    otherSlider();
    careersSlider();
});
import $ from "jquery";
export function mapPosition(){
    const container = document.querySelector('.map__container');
    
    if(container){
        const points = container.querySelectorAll('.point');
         const desc = document.querySelectorAll('.map-desc');
        let centerMatrix = [1, 0, 0, 1, 560, 325]
        let centerX = 54.04;
        let centerY = 33.46;
        
        points.forEach((item, index) => {
            let pointsPositionX = Number(item.dataset.x);
            let pointsPositionY = Number(item.dataset.y);
            let differenceX = 0;
            let differenceY = 0;
            if(pointsPositionX < centerX){
                differenceX = (centerX - pointsPositionX) * 34; //40
            }else{
                differenceX = (centerX - pointsPositionX) * 34;
            }

            if(pointsPositionY < centerY){
                differenceY = (centerY - pointsPositionY) * 50; //-10
            }else{
                differenceY = (centerY - pointsPositionY) * -30;
            }
            
            let coordinat = [1, 0, 0, 1, 600 + differenceY , 510 + differenceX ]
            
            item.style.transform = 'matrix('+coordinat+')'
            let descPositionX = Number(desc[index].dataset.x);
            let descPositionY =  Number(desc[index].dataset.y);
            item.addEventListener('click', function(){
                if(descPositionX == pointsPositionX && descPositionY == pointsPositionY){
                    $('.map-desc').removeClass('active');
                    desc[index].classList.add('active');
                    $('.point').removeClass('active');
                    points[index].classList.add('active');

                    let topPos = desc[index].offsetTop;
                    $(".map__content").animate({scrollTop:topPos - 100},500);
                    console.log(topPos);
                }
            });
        });

        // desc.forEach((item, index) => {
        //     let pointsPositionX = Number(points[index].dataset.x);
        //     let pointsPositionY =  Number(points[index].dataset.y);
        //     item.addEventListener('click', function(){
                
            
        //         let descPositionX = Number(item.dataset.x);
        //         let descPositionY = Number(item.dataset.y);
        //         console.log(descPositionX);
        //         console.log(pointsPositionX);
        //         if(descPositionX == pointsPositionX && descPositionY == pointsPositionY){
        //         $('.map-desc').removeClass('active');
        //         item.classList.add('active');

        //         $('.point').removeClass('active');
        //         points[index].classList.add('active');
        //         }
        //     })
        //     // if(item.classList.contains('active')){
        //     //     let topPos = item.offsetTop;
        //     //     $(".map__content").animate({scrollTop:topPos - 80},500);
        //     //     console.log(topPos);
        //     // }
        //     // topPos = item.classList.contains('active').offsetTop;
        //     // $(".map__content").animate({scrollTop:topPos},500);
        //     // console.log(topPos);
        // });
    }
}
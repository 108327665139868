import Swiper from "swiper/swiper-bundle";

export function mainSlider() {
    const sliderClass = '.mainBlock__slider'
    const swiper = new Swiper(sliderClass, {
        slidesPerView: 1,
        spaceBetween: 30,
        effect: "creative",
        loop: true,
        speed: 800,
        creativeEffect: {
            prev: {
              shadow: true,
              translate: ["-20%", 0, -1],
            },
            next: {
              translate: ["100%", 0, 0],
            },
        },
        // autoplay: {
        //     delay: 10000,
        //     disableOnInteraction: false,
        // },
        pagination: {
            el: sliderClass + " .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: sliderClass + " .swiper-button-next",
            prevEl: sliderClass + " .swiper-button-prev",
        },
    });
    const slider = document.querySelector('.mainBlock__slider');
    if(slider){
        const prev = slider.querySelector('.swiper-button-prev');
         const next = slider.querySelector('.swiper-button-next');
        const slide = slider.querySelector('.secondScreen');
        function paintArrow(){
            if(slide.classList.contains('swiper-slide-active')){
                next.classList.add('white')
                prev.classList.add('white')
            }else{
                next.classList.remove('white')
                prev.classList.remove('white') 
            }
        }
        slider.addEventListener('click', function(){
            paintArrow()
        })
        slider.addEventListener('mouseup', function(){
            paintArrow()
        })
   
    // prev.addEventListener('click', function(){
    //     paintArrow()
    // })
    }
    
}


export function sldierGallery() {
    const sliderClassThumb = '.sldierGallery-thumb';
    const sliderClass = '.sldierGallery';
    const swiper = new Swiper(sliderClassThumb, {
        loop: false,
        spaceBetween: 20,
        slidesPerView: 3,
        watchSlidesProgress: true,
        breakpoints: {
            450: {
                slidesPerView: 4,
            },
            1000: {
                slidesPerView: 5,
            }
        }
    });
    const swiper2 = new Swiper(sliderClass, {
        loop: false,
        spaceBetween: 10,
        navigation: {
            // nextEl: sliderClass +" .swiper-button-next",
            // prevEl: sliderClass +" .swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });
}

export function sliderMaterial() {
    const sliderClassThumb = '.sliderMaterial-thumb';
    const sliderClass = '.sliderMaterial';
    const swiper = new Swiper(sliderClassThumb, {
        loop: false,
        spaceBetween: 20,
        slidesPerView: 2,
        watchSlidesProgress: true,
        breakpoints: {
            1100: {
                slidesPerView: 3,
            }
        }
    });
    const swiper2 = new Swiper(sliderClass, {
        loop: false,
        spaceBetween: 10,
        navigation: {
             nextEl: sliderClass +" .swiper-button-next",
             prevEl: sliderClass +" .swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });
}


export function otherSlider() {
    const sliderClass = '.otherSlider';
    
    const swiper = new Swiper(sliderClass, {
        slidesPerView: 1,
        spaceBetween: 40,
        navigation: {
            nextEl: ".other .swiper-button-next",
            prevEl: ".other .swiper-button-prev",
       },
       breakpoints: {
        420: {
            slidesPerView: 2,
        },
        600: {
            slidesPerView: 3,
        },
        1100: {
            slidesPerView: 4,
        }
       }
      });
}

export function careersSlider() {
    const sliderClass = '.careersSlider';
    
    const swiper = new Swiper(sliderClass, {
        slidesPerView: 1,
        spaceBetween: 40,
        navigation: {
            nextEl: ".careers .swiper-button-next",
            prevEl: ".careers .swiper-button-prev",
       },
       breakpoints: {
        450: {
            slidesPerView: 2,
        },
        700: {
            slidesPerView: 3,
        }
       }
      });
}